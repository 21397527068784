/* Fonts */
@font-face {
    font-family: Roboto;
    /*src: url("../fonts/Roboto-Regular.ttf");*/
}

@font-face {
    font-family: Roboto-Light;
    /*src: url("../fonts/Roboto-Light.ttf");*/
}

/* Animations */
@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}

/* Elements */
a {
    text-decoration: none;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-weight: 300;
}

body {
    align-items: center;
    background-color: #1f1f1f;
    color: #ddd;
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
}

main {
    animation-duration: 1s;
    animation-name: fade-in;
    font-family: Roboto-Light, sans-serif;
    margin: auto;
    width: 100%;
}

/* Classes */
.links-container {
    width: 100%;
}

.link-section {
    background-color: #353535;
    padding: 10px;
    overflow-y: auto;
    box-shadow: 0px 0px 15px 2px #111;
}

.link-section-title {
    font-size: 20px;
    margin: 0;
    font-weight: normal;
}

.link-list {
    padding: 0;
}

.link-list-item {
    align-items: center;
    display: flex;
    height: 2em;
    justify-content: center;
    line-height: 2em;
    list-style-type: none;
    margin: 1vh 0 1vh 0;
    text-align: center;
    width: 100%;
}

.link-list-item:hover {
    background-color: #888;
}

.link {
    color: #ddd;
    display: block;
    width: 100%;
    height: 100%;
}

.link-section:nth-child(1) .link-section-title{
    color: #ff6b6b;
}

.link-section:nth-child(2) .link-section-title{
    color: #48b1ff;
}

.link-section:nth-child(3) .link-section-title{
    color: #9348ff;
}

.link-section:nth-child(4) .link-section-title{
    color: #65ff66;
}

.link-section:nth-child(5) .link-section-title{
    color: #ff030d;
}

.link-section:nth-child(6) .link-section-title{
    color: #f498d2;
}

.link-section:nth-child(7) .link-section-title{
    color: #19fbed;
}

.link-section:nth-child(8) .link-section-title{
    color: #f7f700;
}

/* IDs */
#clock {
    font-family: Roboto;
}

#top-div {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-end: 5;
    grid-column-start: 1;
    text-align: center;
    padding: 0 5vw 0 5vw;
}

#search-form, #clock-div {
    align-items: center;
    display: flex;
    justify-content: center;
}

#search-bar-wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}

#search-bar, #search-bar-btn {
    background-color: #353535;
    border-color: #353535;
    border-style: solid;
    border-width: 1px;
}

#search-bar {
    box-shadow: 0px 0px 15px 2px #111;
    box-sizing: border-box;
    color: #ddd;
    font-size: 16px;
    height: 25px;
    padding: 0 25px 0 0;
    text-align: center;
    width: 100%;
}

#search-bar-btn {
    display: inline-block;
    font-size: 16px;
    height: 25px;
    padding: 0 0 0 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
}

#search-bar:focus, #search-bar:focus + #search-bar-btn {
    border-color: #888;
}

#links-container {
    column-gap: 0vw;
    display: grid;
    grid-column-gap: 0vw;
    grid-row-gap: 5vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    justify-content: center;
    padding: 2vh 5vw 2vh 5vw;
    row-gap: 5vh;
}

/* Media Queries */
@media only screen and (min-width: 600px) {

    #links-container {
        column-gap: 3vw;
        grid-column-gap: 3vw;
        grid-row-gap: 5vh;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 5vh;
    }

}

@media only screen and (min-width: 768px) {

    #links-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
    }

}

@media only screen and (min-width: 1200px) {

    #top-div {
        padding: 0 15vw 0 15vw;
    }

    #links-container {
        padding: 2vh 15vw 2vh 15vw;
    }

}